module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Emma James Tattoo","description":"Emma James is a queer, Toronto-based tattoo artist, who tattoos in many different styles. Emma strives to create a tattoo environment which is free from judgement, and open to people of all bodies, genders, races and ages (well, aged from 18+ at least).","icon":"./static/favicon.png","start_url":"/","background_color":"#000","theme_color":"#FF00B7","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"94a4f631ff61a70fa515ec254cc6c2b6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    }]
